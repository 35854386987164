import React from "react";

export default ({ disabled, onClick }) => {
  return (
    <button
      className="btn btn-danger m-3"
      disabled={disabled}
      onClick={onClick}
    >
      {disabled ? "Ending..." : "End Chat"}
    </button>
  );
};
