import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

export const Main = styled.main`
  margin: 0 auto;
  max-width: 42em;
`;
