import React from "react";
import { Svg } from "./styles";

export default () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1209.71 255.79">
    <path d="M772.21,5.79a16,16,0,0,0-16,16v214a16,16,0,0,0,32,0v-214A16,16,0,0,0,772.21,5.79Z" />
    <path d="M824.21,73.79a16,16,0,0,0-16,16v146a16,16,0,0,0,32,0v-146A16,16,0,0,0,824.21,73.79Z" />
    <path d="M824.21,5.79a16,16,0,0,0-16,16v24a16,16,0,0,0,32,0v-24A16,16,0,0,0,824.21,5.79Z" />
    <path d="M476.21,73.79a16,16,0,0,0-16,16v146a16,16,0,1,0,32,0v-146A16,16,0,0,0,476.21,73.79Z" />
    <path d="M476.21,5.79a16,16,0,0,0-16,16v24a16,16,0,1,0,32,0v-24A16,16,0,0,0,476.21,5.79Z" />
    <path d="M589.21,94.79a16,16,0,0,0-16-16H545.32c.78-7.1,3.23-17.07,10.44-25.17,9.34-10.51,25-15.83,46.45-15.83a16,16,0,0,0,0-32c-31,0-54.69,8.94-70.37,26.56-20.2,22.73-19.12,51.91-18.63,58.15V235.79a16,16,0,0,0,32,0v-125h28A16,16,0,0,0,589.21,94.79Z" />
    <path d="M430.21,219.79H384l-.44,0c-1.16,0-19.74.13-32.55-12.27-9.12-8.83-13.75-22.54-13.75-40.74v-145a16,16,0,0,0-32,0v145c0,27.43,8,49,23.87,64.1,20.54,19.58,47.38,20.91,54.27,20.91h46.86a16,16,0,0,0,0-32Z" />
    <path d="M737.66,190.24a16,16,0,0,0-22.21,4.3,57.25,57.25,0,0,1-75.36,18l90.73-90.74a16,16,0,0,0-.06-22.68,89.23,89.23,0,0,0-129.18,123,16.06,16.06,0,0,0,6.16,6.22A89.19,89.19,0,0,0,742,212.45,16,16,0,0,0,737.66,190.24Zm-126.95-27.7a57.3,57.3,0,0,1,84.5-50.38l-77.6,77.6A56.85,56.85,0,0,1,610.71,162.54Z" />
    <path d="M1202.66,190.24a16,16,0,0,0-22.21,4.3,57.25,57.25,0,0,1-75.36,18l90.73-90.74a16,16,0,0,0-.06-22.68,89.23,89.23,0,0,0-129.18,123,16.06,16.06,0,0,0,6.16,6.22A89.19,89.19,0,0,0,1207,212.45,16,16,0,0,0,1202.66,190.24Zm-127-27.7a57.3,57.3,0,0,1,84.5-50.38l-77.6,77.6A56.85,56.85,0,0,1,1075.71,162.54Z" />
    <path d="M945,73.56a89.08,89.08,0,0,0-89,89v72.75a15.73,15.73,0,0,0,31.46,0V162.54a57.52,57.52,0,1,1,115,0v72.75a15.73,15.73,0,0,0,31.46,0V162.54A89.07,89.07,0,0,0,945,73.56Z" />
    <path d="M78.89,131a18.9,18.9,0,1,0,18.9,18.89A18.92,18.92,0,0,0,78.89,131Z" />
    <path d="M177.89,87a18.9,18.9,0,1,0,18.9,18.89A18.92,18.92,0,0,0,177.89,87Z" />
    <path d="M106.78,97A18.9,18.9,0,1,0,88.13,77.88,18.82,18.82,0,0,0,106.78,97Z" />
    <path d="M149.53,196.56a18.9,18.9,0,0,0,.48-37.79,18.9,18.9,0,0,0-.48,37.79Z" />
    <path d="M236.89,109a18.9,18.9,0,1,0,18.9,18.89A18.92,18.92,0,0,0,236.89,109Z" />
    <path d="M127.89,109a18.9,18.9,0,1,0,18.9,18.89A18.92,18.92,0,0,0,127.89,109Z" />
    <path d="M18.89,109a18.9,18.9,0,1,0,18.9,18.89A18.92,18.92,0,0,0,18.89,109Z" />
    <path d="M127.89,218a18.9,18.9,0,1,0,18.9,18.89A18.92,18.92,0,0,0,127.89,218Z" />
    <path d="M127.89,0a18.9,18.9,0,1,0,18.9,18.89A18.92,18.92,0,0,0,127.89,0Z" />
    <path d="M205.21,185.83h-.06a18.9,18.9,0,0,0,.06,37.79h.06a18.73,18.73,0,0,0,13.34-5.57h0a18.9,18.9,0,0,0-13.4-32.22Z" />
    <path d="M50.58,32.17h-.06a19,19,0,1,0,.06,0Z" />
    <path d="M51.06,186.32a18.89,18.89,0,0,0-13.32,32.29h0a18.8,18.8,0,0,0,13.32,5.49h.06a18.72,18.72,0,0,0,13.34-5.57,18.89,18.89,0,0,0-13.4-32.21Z" />
    <path d="M191.41,64a18.77,18.77,0,0,0,13.32,5.49h.06a18.9,18.9,0,0,0-.06-37.79h-.06A18.89,18.89,0,0,0,191.41,64Z" />
    <path d="M228.66,150.61a18.89,18.89,0,1,0,17.44,26.11h0a18.89,18.89,0,0,0-17.44-26.11Z" />
    <path d="M27.17,67.39a18.89,18.89,0,1,0,7.19,1.43A18.91,18.91,0,0,0,27.17,67.39Z" />
    <path d="M86.29,209.74a18.91,18.91,0,1,0,7.21,1.44A18.84,18.84,0,0,0,86.29,209.74Z" />
    <path d="M169.53,8.25a18.89,18.89,0,0,0-7.24,36.36h0A18.89,18.89,0,1,0,176.72,9.69,18.68,18.68,0,0,0,169.53,8.25Z" />
    <path d="M170.41,209.36a18.89,18.89,0,1,0,7.4,36.29h0a18.89,18.89,0,0,0-7.4-36.29Z" />
    <path d="M85.35,8.63a18.9,18.9,0,1,0,7.38,36.3h0A18.89,18.89,0,0,0,92.44,10,19,19,0,0,0,85.35,8.63Z" />
    <path d="M27.51,151.54a18.89,18.89,0,1,0,17.42,11.52A18.77,18.77,0,0,0,27.51,151.54Z" />
    <path d="M210.85,92.73a18.9,18.9,0,1,0,10-24.77,18.78,18.78,0,0,0-10,24.77Z" />
    <path d="M78.22,88.56a18.9,18.9,0,1,0,13.1,32.52h0A18.9,18.9,0,0,0,78.6,88.56Z" />
    <path d="M178.55,129.44a18.47,18.47,0,1,0,.38,0Z" />
    <path d="M148.39,58.66a18.89,18.89,0,0,0-13.5,32.11h0A18.9,18.9,0,1,0,148.6,58.66Z" />
    <path d="M108.39,159.34a18.66,18.66,0,1,0,.21,0Z" />
  </Svg>
);
