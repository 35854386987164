import React from "react";
import { Router } from "@reach/router";
import { Helmet } from "react-helmet";

import Home from "./containers/Home";
import Iss from "./containers/Iss";

import Page from "./components/Page";

const App = () => {
  let title = "Remote In Shift Supervision";
  if (["LOCAL", "DEVELOPMENT"].includes(process.env.REACT_APP_ENV)) {
    title = `Dev ${title}`;
  }
  return (
    <Page>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Router>
        <Home path="/" />
        <Iss path="/iss" />
      </Router>
    </Page>
  );
};

export default App;
