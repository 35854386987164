import React from "react";

export default () => (
  <>
    <h1 className="cover-heading">In Shift Supervision Login</h1>

    <p className="lead">
      Are you an In Shift Supervisor? You can login to the ISS Portal from this
      page.
    </p>
    <p className="lead">For testing you can login with the button below.</p>
    <a
      type="button"
      className="btn btn-primary btn-lg"
      href="https://flex.twilio.com/agent-desktop/"
    >
      Launch ISS Portal
    </a>
  </>
);
