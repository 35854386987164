import React from "react";

import WebChat from "../../components/WebChat";

export default () => {
  return (
    <>
      <h1 className="cover-heading">In Shift Supervision Chat</h1>
      <p className="lead">
        During remote working arrangements in shift support is provided by a
        remote pool of staff and is available via web chat.
      </p>
      <p className="lead">
        You can use webchat to request in shift supervisor support for for both{" "}
        <strong>urgent</strong> and <strong>non-urgent</strong> requests.
      </p>
      <p className="lead">
        To start a chat simply click the blue <em>chat with us</em> button in
        the lower right corner of the screen.
      </p>
      <WebChat />
    </>
  );
};
