import styled from "styled-components";

export const Header = styled.header`
  align-items: center;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 48px;
  margin-bottom: auto;
  padding: 0 80px;

  svg {
    display: block;
    height: 50%;
  }
`;
