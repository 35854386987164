export default {
  light: "#f7f8f9",
  primary: "#013d79",
  secondary: "#faa634",
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700
  },
  grey: {
    800: "#48535b",
    900: "#182a33"
  }
};
