import React from "react";
import { Main, Wrapper } from "./styles";

import Footer from "../Footer";
import Header from "../Header";

export default ({ children, ...props }) => (
  <Wrapper {...props}>
    <Header />
    <Main>
      <div className="container">{children}</div>
    </Main>
    <Footer />
  </Wrapper>
);
