import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  :root {
    ${({ theme }) => `
      --primary: ${theme.primary};
      --secondary: ${theme.secondary};
    `};
  }

  body {
    background: ${({ theme }) => theme.light};
    color: ${({ theme }) => theme.grey["800"]};
    font-family: 'Quicksand', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    color: ${({ theme }) => theme.grey["900"]};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  .Twilio-WelcomeMessage {
    display: none !important;
  }
`;
