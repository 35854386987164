import theme from "../../theme";

export const BOT_NAME = "ISS Bot";

export const defaultMessages = {
  PredefinedChatMessageAuthorName: BOT_NAME,
  PredefinedChatMessageBody:
    "Hello. Do you need to contact an in shift supervisor?",
  EntryPointTagline: "Contact ISS",
};

export const appConfig = {
  colorTheme: {
    baseName: "BlueLight",
    light: true,
    overrides: {
      MainHeader: {
        Container: {
          background: theme.primary,
          color: theme.light,
        },
      },
    },
  },
  accountSid: process.env.REACT_APP_ACCOUNT_SID,
  flexFlowSid: process.env.REACT_APP_FLEX_FLOW_ID,
  startEngagementOnInit: false,
  context: {},
  preEngagementConfig: {
    description:
      "We will collect your information so that the supervisor can provide support.",
    fields: [
      {
        label: "What is your full name?",
        type: "InputItem",
        attributes: {
          name: "friendlyName",
          type: "text",
          required: true,
        },
      },
    ],
    submitLabel: "Submit request",
  },
};
